import useAxios from "axios-hooks";
import { Form, Formik, FormikProps } from "formik";
import { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import Select from "react-select";
import Select2Buttons from "../Components/Select2Buttons";
import { SelectStyle } from "../Helper/ReactSelect";
import { months } from "../Helper/Year";
import * as Yup from "yup";
import ErrorLabel from "../Components/ErrorLabel";
import EnergyTipSaveModal from "../Models/EnergyTipSaveModal";
import { getUserData } from "../Services/Auth";
import { useAlert } from "react-alert";

type EnergyTip = {
  imageUrl: null;
  category: string;
  text: string;
  siteId: number;
};

const schema = Yup.object({
  category: Yup.number().required("Category is required"),
  siteId: Yup.number().required("Site is required"),

  month: Yup.number().required("Month is required"),
  energyTip: Yup.array()
    .min(1, "Energy Tip type is required")
    .required("Energy Tip type is required"),
});

const initial = {
  energyTip: [] as number[],
  month: null as number | null | undefined,
  category: null as number | null | undefined,
  siteId: null as number | null | undefined,
};

export default function UserManagementForm() {
  const formikRef = useRef<FormikProps<typeof initial> | null>(null);
  const alert = useAlert();

  const [energyTips] = useAxios<EnergyTip[]>({ url: "/utility/savingTips" });
  const [siteList] = useAxios<any>({ url: "/business/sites" });

  const [saveReq, saveTip] = useAxios(
    {
      url: "/utility/mapTipToBusiness",
      method: "post",
    },
    { manual: true }
  );

  const [eneryList, setEnegyList] = useState<
    { value: number; label: string }[]
  >([]);

  const [sitesList, setSitesList] = useState<
    { value: number; label: string }[]
  >([]);

  const [categoryID, setCategoryID] = useState<any>();

  const categoryList: { value: number; label: string }[] = [
    { value: 0, label: "Heating" },
    { value: 1, label: "Cooling" },
    { value: 2, label: "Lighting & Power" },
  ];

  useEffect(() => {
    if (energyTips.data) {
      const label = categoryID
        ? categoryID?.label == "Lighting & Power"
          ? "Power"
          : categoryID.label
        : "";
      const ls = energyTips.data
        ?.filter((x: any) => x.category == label)
        .map((x: any) => ({
          label: x.text,
          value: x.id,
        }));
      setEnegyList(ls);
    }
  }, [energyTips, categoryID]);

  useEffect(() => {
    if (siteList.data) {
      const ls = siteList.data?.map((x: any) => ({
        label: x.name,
        value: x.id,
      }));
      setSitesList(ls);
    }
  }, [siteList]);

  return (
    <section className="w-55 mt-5">
      <Formik
        innerRef={formikRef}
        initialValues={initial}
        validationSchema={schema}
        onSubmit={async (values, { resetForm, setFieldValue }) => {
          if (values.month === null || values.month === undefined) return;
          if (values.category === null || values.category === undefined) return;
          if (!values.energyTip) return;

          try {
            console.log("values => ", values);
            const detail: EnergyTipSaveModal = {
              businessId: getUserData().id,
              month: values.month,
              tipId: values.energyTip,
              use: categoryList[values.category].label,
              siteId: values.siteId ?? -1,
            };

            const response = await saveTip({ data: detail });
            console.log("response => ", response);

            if (response.status === 200) {
              alert.show("Success");
              resetForm();

              // setFieldValue("month", null);
              // setFieldValue("category", null);
            } else {
              alert.show("Error");
            }
          } catch (err) {
            alert.show("Error");
          }
        }}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          setFieldValue,
          setFieldTouched,
        }) => {
          return (
            <Form>
              <div
                className="card rounded-custom"
                style={{
                  filter: "drop-shadow(0px 0px 20px #E7E7E866)",
                }}
              >
                <div className="card-header bg-white rounded-custom-top py-4">
                  <h2 className="text-center mb-0 fw-bold">User Management</h2>
                </div>
                <div
                  className="card-body"
                  style={{
                    paddingBottom: "2.2rem",
                    paddingLeft: "2.2rem",
                    paddingRight: "2.2rem",
                    paddingTop: "2.2rem",
                  }}
                >
                  <div className="row mb-5">
                    <div className="col-12 text-muted mb-1">
                      <div>
                        <label>Sites</label>
                        <Select
                          name="siteId"
                          id="siteId"
                          options={sitesList}
                          styles={SelectStyle}
                          placeholder={
                            <span className="select-placeholder">Site</span>
                          }
                          onChange={(value: any) => {
                            setFieldValue("siteId", value.value);
                          }}
                        />

                        <ErrorLabel
                          render={
                            touched.siteId === true &&
                            errors.siteId !== undefined
                          }
                        >
                          {errors.siteId}
                        </ErrorLabel>
                      </div>
                      <div className="row">
                        <div className="col-6 ">
                          <label>Month</label>
                          <Select2Buttons
                            options={months}
                            placeholder="Month"
                            onChange={(value: any) => {
                              setFieldValue("month", value.value);
                            }}
                          />
                          <ErrorLabel
                            render={
                              touched.month === true &&
                              errors.month !== undefined
                            }
                          >
                            {errors.month}
                          </ErrorLabel>
                        </div>
                        <div className="col-6">
                          <label>Category</label>
                          <Select2Buttons
                            options={categoryList}
                            placeholder="Category"
                            onChange={(value: any) => {
                              setFieldValue("category", value.value);
                              setCategoryID(value);
                            }}
                          />
                          <ErrorLabel
                            render={
                              touched.category === true &&
                              errors.category !== undefined
                            }
                          >
                            {errors.category}
                          </ErrorLabel>
                        </div>
                      </div>
                      <label htmlFor="energyTip" className="form-label">
                        Energy Tips
                      </label>
                      <Select
                        name="energyTip"
                        id="energyTip"
                        isMulti
                        options={eneryList}
                        value={eneryList.filter((option) =>
                          values.energyTip.includes(option.value)
                        )}
                        styles={SelectStyle}
                        placeholder={
                          <span className="select-placeholder">
                            Choose Energy Tips
                          </span>
                        }
                        onChange={(selected) => {
                          // Extract values from the selected options
                          const selectedValues = selected
                            ? selected.map((option) => option.value)
                            : [];
                          setFieldValue("energyTip", selectedValues); // Update Formik state
                        }}
                        onBlur={() => {
                          setFieldTouched("energyTip", true, true); // Set the field as touched
                          handleBlur({ target: { name: "energyTip" } });
                        }}
                      />
                      <ErrorLabel
                        render={
                          touched.energyTip === true &&
                          errors.energyTip !== undefined
                        }
                      >
                        Please select at least 1 Energy tip
                      </ErrorLabel>
                    </div>
                  </div>
                </div>
              </div>

              <div className="d-flex justify-content-between mt-3 mb-5">
                <button
                  className="btn btn-outline-danger btn-nav"
                  type="submit"
                  disabled={saveReq.loading}
                >
                  <i className="fas fa-save me-3"></i>SAVE
                </button>
                <span>
                  <Link
                    to="/home/settings/building-energy-patterns"
                    className="btn btn-danger btn-nav"
                  >
                    <i className="fas fa-long-arrow-alt-left me-1"></i>BACK{" "}
                  </Link>
                  <Link
                    to="/home/analytics"
                    className="btn btn-danger ms-3 btn-nav"
                  >
                    NEXT <i className="fas fa-long-arrow-alt-right ms-1"></i>
                  </Link>
                </span>
              </div>
            </Form>
          );
        }}
      </Formik>
    </section>
  );
}
