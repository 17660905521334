import useAxios from "axios-hooks";
import { getDaysInMonth, setMonth } from "date-fns";
import { useEffect } from "react";
import { createGlobalState } from "react-hooks-global-state";
import { useSelector } from "react-redux";
import FuelSource from "../../Models/FuelSource";
import { Site } from "../../Models/Site";
import { State } from "../../State";

const initialState = {
  isLoading: false,
  sites: [] as any[],
  carbonEmissions: [] as any[],
  energyWaste: [] as any[],
  fuelSources: [] as FuelSource[],
};
const { useGlobalState } = createGlobalState(initialState);

const PortfolioState = () => {
  const [isLoading, setLoading] = useGlobalState("isLoading");
  const [sites, setSites] = useGlobalState("sites");
  const [carbonEmissions, setCarbonEmissions] =
    useGlobalState("carbonEmissions");
  const [energyWaste, setEnergyWaste] = useGlobalState("energyWaste");
  const [fuelSources, setFuelSources] = useGlobalState("fuelSources");
  const year = useSelector((state: State) => state.year);
  const month = useSelector((state: State) => state.month);
  const fuelSource = useSelector((state: State) => state.fuelSource);

  const [getSitesReq, getSites] = useAxios<Site[]>(
    {
      url: "/business/sites",
    },
    { manual: true }
  );

  const [getPortfolioDataReq, getPortfolioData] = useAxios<{
    carbonEmissions: any[];
    energyTargets: any[];
  }>(
    {
      url: "/dashboard/portfolio",
    },
    { manual: true }
  );

  const [fuelSourceReq, getFuelSource] = useAxios<FuelSource[]>(
    {
      url: "/utility/fuelSources",
    },
    { manual: true }
  );

  const isFetching =
    getPortfolioDataReq.loading || fuelSourceReq.loading || getSitesReq.loading;

  useEffect(() => {
    setLoading(isFetching);
  }, [getPortfolioDataReq.loading]);

  useEffect(() => {
    setLoading(isFetching);
    if (fuelSourceReq.data && fuelSourceReq.data.length !== 0) {
      setFuelSources(fuelSourceReq.data);
    }
  }, [fuelSourceReq.data, fuelSourceReq.loading]);

  useEffect(() => {
    setLoading(isFetching);
    if (getSitesReq.data && getSitesReq.data.length !== 0) {
      setSites(getSitesReq.data);
    }
  }, [getSitesReq.data, getSitesReq.loading]);

  const fetchSites = () => {
    return getSites().then(({ data }) => {
      if (data.length !== 0) {
        console.log("Site data", data);
        setSites(data);
      }
    });
  };

  const fetchPortfolioData = () => {
    if (!year) return;
    if (!fuelSource) return;
    if (!month) return;
    return getPortfolioData({
      url: `/dashboard/portfolio?year=${year.value}&fuelSourceId=${fuelSource.value}&month=${month.value}`,
    }).then(({ data }) => {
      setCarbonEmissions(data.carbonEmissions);
      setEnergyWaste(data.energyTargets);
    });
  };

  const selectedMonthDaysAmount = getDaysInMonth(
    setMonth(new Date(), month.value)
  );

  return {
    fetchSites,
    fetchPortfolioData,
    getFuelSource,
    fuelSources,
    energyWaste,
    isLoading: isLoading,
    sites,
    carbonEmissions,
    selectedMonthDaysAmount,
  };
};

export default PortfolioState;
