import style from "../analytics.module.css";
import Select from "react-select";
import { SelectStyle } from "../../Helper/ReactSelect";
import { months, years } from "../../Helper/Year";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { ActionCreators, State } from "../../State";
import SideMenuState from "../UtilityLeague/LeageState";
import { useEffect, useState } from "react";
import SiteList from "./Site";
import { useConvertHtmlToImg } from "../../Hooks/useConvertHtmlToImg";
import { loggedIn } from "../../Services/Auth";
import useQueryManager from "../../Hooks/useQueryManager";
import { defaultFuelSourceOption } from "./Base";

export type ChartRecord = {
  date: string;
  cost: number;
  consumption: number;
  averageConsumption: number;
};

export default function CFootPrint() {
  const urlQuery = useQueryManager();
  const dispatch = useDispatch();
  const { ChangeDisplay, ChangeMonth, ChangeYear, ChangeFuelSource } =
    bindActionCreators(ActionCreators, dispatch);
  const display = useSelector((state: State) => state.display);
  const month = useSelector((state: State) => state.month);
  const year = useSelector((state: State) => state.year);
  const fuelSource = useSelector((state: State) => state.fuelSource);
  const site = useSelector((state: State) => state.site);
  const { convertHtmlToPdf } = useConvertHtmlToImg();
  const [extracted, setExtracted] = useState<String>();

  const sideMenuState = SideMenuState();

  useEffect(() => {
    sideMenuState.getFuelSource();

    const month = urlQuery.get("month");
    if (month) {
      const found = months.find((m) => m.value === Number.parseInt(month));
      if (found) {
        ChangeMonth(found);
      }
    }

    const year = urlQuery.get("year");
    if (year) {
      const found = years.find((m) => m.value === Number.parseInt(year));
      if (found) {
        ChangeYear(found);
      }
    }
  }, []);

  useEffect(() => {
    if (sideMenuState.fuelSources.length !== 0) {
      const fuelSource = urlQuery.get("fuel");
      if (fuelSource) {
        const found = sideMenuState.fuelSources
          .map((i) => ({ value: i.id, label: i.source }))
          .concat([defaultFuelSourceOption])
          .find((m) => m.value === Number.parseInt(fuelSource));

        if (found) {
          ChangeFuelSource({
            value: found.value,
            label: found.label,
          });
        }
      }
    }
  }, [sideMenuState.fuelSources]);

  useEffect(() => {
    const consumption = sideMenuState.carbonFootPrint.flat();
    const extractedSet = new Set(consumption.map((item) => item.usedIn));
    const extractedArray = Array.from(extractedSet);
    const extractedString = extractedArray.join(", "); // Join Array to create a string
    setExtracted(extractedString);
  }, [sideMenuState.carbonFootPrint]);

  const downloadPdfFile = async () => {
    const getPanelId = () => {
      switch (display.value) {
        case 0:
          return "consumption";
        case 1:
          return "carbonEmissionTable";
        case 2:
          return "chatGraph";
        default:
          return "consumption";
      }
    };
    const graphList = [
      { svgId: getPanelId(), panelTitle: "Carbon Emissions (kg)" },
      { svgId: "baseline", panelTitle: "Carbon Baseline (kg)" },
      {
        svgId: "carbonGauge",
        panelTitle: "Carbon Gauge(Emissions in the last 3 months)",
      },
      {
        svgId: "emissions",
        panelTitle: "Utilities (Emissions in the last 3 months)",
      },
    ];
    const selectedSiteName = site?.name
      ? site?.name
      : sideMenuState?.sites?.map((x) => x.name.trim()).join(", ");
    const selectedYearAndMonth = `${month.label}/${year.label}`;
    const selectedFuelSourceType = fuelSource?.label ?? "Grid Electricity";

    convertHtmlToPdf(
      graphList,
      selectedSiteName,
      selectedYearAndMonth,
      selectedFuelSourceType,
      "CarbonFootPrints"
    );
  };

  return (
    <div className={style.rightSideBar}>
      <div className={sideMenuState.isLoading ? style.baseMenuBusy : "d-none"}>
        <div className="spinner-border" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
      {loggedIn() === false && (
        <div className={style.login}>
          {" "}
          <button className="btn btn-success btn-radius px-4 py-2">
            <i className="fas fa-user me-1"></i>
            Login
          </button>{" "}
        </div>
      )}
      <SiteList sites={sideMenuState.sites} currentSite={site} />
      <div className="px-4 my-3">
        <div className="mb-2">
          <label className={`form-label ${style.selectLabel}`}>Month</label>
          <Select
            options={months}
            styles={SelectStyle}
            placeholder={<span className="select-placeholder">Choose</span>}
            value={month}
            onChange={(e) => {
              if (e) {
                ChangeMonth(e);
                urlQuery.set("month", e.value.toString());
              }
            }}
          />
        </div>
        <div className="mb-2">
          <label className={`form-label ${style.selectLabel}`}>Year</label>
          <Select
            options={years}
            styles={SelectStyle}
            value={year}
            placeholder={<span className="select-placeholder">Choose</span>}
            onChange={(e) => {
              if (e) {
                ChangeYear(e);
                urlQuery.set("year", e.value.toString());
              }
            }}
          />
        </div>
        <div className="mb-2">
          <label className={`form-label ${style.selectLabel}`}>
            Fuel Source
          </label>
          <Select
            options={[defaultFuelSourceOption].concat(
              sideMenuState.fuelSources.map((i) => ({
                value: i.id,
                label: i.source,
              }))
            )}
            styles={SelectStyle}
            placeholder={<span className="select-placeholder">Choose</span>}
            value={fuelSource}
            onChange={(e) => {
              if (e) {
                ChangeFuelSource(e);
                urlQuery.set("fuel", e.value.toString());
              }
            }}
          />
        </div>

        <div className="mb-2">
          <label className={`form-label ${style.selectLabel}`}>Energy</label>
          {extracted && <p>{extracted}</p>}
        </div>

        <div className="mb-2">
          <label className={`form-label ${style.selectLabel}`}>Display</label>
          <Select
            options={[
              { value: 0, label: "Graph" },
              { value: 1, label: "Table" },
              { value: 2, label: "Chart" },
            ]}
            styles={SelectStyle}
            value={display}
            onChange={(e) => {
              if (e) {
                ChangeDisplay(e);
              }
            }}
          />
        </div>

        <div className="mb-3">
          <label className={`form-label ${style.selectLabel}`}>Sort by</label>
          <Select
            options={[
              { value: 0, label: "Highest Emission" },
              { value: 1, label: "Lowest Emission" },
            ]}
            styles={SelectStyle}
            placeholder={<span className="select-placeholder">Choose</span>}
            defaultValue={{ value: 0, label: "Highest Emission" }}
          />
        </div>
      </div>
      <div className="px-4 mb-2">
        <button
          className={`btn btn-danger mb-2 py-3 w-100 ${style.buttons}`}
          onClick={() => downloadPdfFile()}
        >
          Download Entire Document
        </button>
        <button
          className={`btn btn-outline-danger border-2 py-3 w-100 ${style.buttons}`}
        >
          Send to Email
        </button>
      </div>
    </div>
  );
}
