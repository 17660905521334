import useAxios from "axios-hooks";
import Layout from "./Layout";
import EnergySavingTipsSide from "./SideMenu/EnergySavingTips";
import style from "./analytics.module.css";
import { State } from "../State";
import { useSelector } from "react-redux";
import { getUserData } from "../Services/Auth";
import { useEffect, useState } from "react";
import EnergyTipSaveModal from "../Models/EnergyTipSaveModal";
import { AxiosResponse } from "axios";

type EnergyTip = {
  id: number;
  imageUrl: null;
  category: string;
  text: string;
};

export default function EnergySavingTips() {
  const [energyTips] = useAxios<EnergyTip[]>({ url: "/utility/savingTips" });

  const [selectedValue, setSelectedValue] = useState<String[]>();
  const category = useSelector((state: State) => state.category);
  const month = useSelector((state: State) => state.month.value);
  const site = useSelector((state: State) => state.site?.id);

  const [_, getEnergyTip] = useAxios(
    {
      url: `/utility/businessTips?businessId=${getUserData().id}`,
      method: "get",
    },
    { manual: true }
  );
  const [selectedEnergyTips, setSelectedEnergyTips] =
    useState<AxiosResponse<any, any>>();
  let filteredTips;
  if (category.value == 0) {
    filteredTips = energyTips?.data;
  } else {
    let label = category.label;
    if (label == "Lighting & Power") {
      label = "Power";
    }
    filteredTips = energyTips?.data?.filter((tip) => tip.category === label);
  }

  const callEnergyTip = async () => {
    const data = await getEnergyTip();
    setSelectedEnergyTips(data);
  };
  useEffect(() => {
    callEnergyTip();

    // if (selectedEnergyTips.data) {
    //   const monthData = selectedEnergyTips.data.filter((value) => {
    //     return value.month == month;
    //   });
    //   console.log("MonthData", monthData);
    //   const tipData = monthData.map((val) => val.tipId.toString());
    //   setSelectedValue(tipData);
    //   console.log("TipData", tipData);
    // }
  }, []);

  useEffect(() => {
    if (selectedEnergyTips?.data) {
      const monthData = selectedEnergyTips.data?.filter((value: any) => {
        return value.month == month;
      });

      const siteData = selectedEnergyTips.data.filter((value: any) => {
        return value.siteId == site;
      });
      const tipIds = monthData?.map((val: any) => val?.tipId?.toString());
      const siteIds = siteData?.map((val: any) => val?.tipId?.toString());
      setSelectedValue([...tipIds, ...siteIds]);
    }
  }, [selectedEnergyTips, month, site]);

  return (
    <Layout rightSideBar={<EnergySavingTipsSide />}>
      <div className="flex-fill d-flex flex-column">
        <h4 className={`${style.sectionHeader} mt-4 text-center`}>
          Energy Saving Tips
        </h4>
        <div className="flex-fill mx-3 mb-4 position-relative">
          <div className={`${style.full} p-3`}>
            <div className={`card ${style.analyticsCard}`}>
              <div className="card-header d-flex justify-content-between align-items-center bg-white ps-4">
                <span className={style.cardTitle}>Energy Saving Tips</span>
              </div>
              <div className="card-body row">
                {filteredTips?.map((item: any) => {
                  if (!item.imageUrl) {
                    return <></>;
                  }

                  return (
                    <div
                      className={`col-4 ps-2 pe-2 m-1 ${
                        selectedValue?.toString().includes(item?.id?.toString())
                          ? style.highlight
                          : ""
                      }`}
                      key={item.imageUrl}
                    >
                      <img
                        className="w-100"
                        alt={item.category}
                        src={`${process.env.REACT_APP_API_URL?.slice(0, -4)}${
                          item.imageUrl
                        }`}
                      />
                      <p className="text-center">{item.text}</p>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}
