import { ApiStringDateToDate } from "../../Helper/ApiStringDateToDate";
import { ChartYears } from "../../Helper/DateFormat";
import ReportState from "../Report/ReportState";
import styles from "./styles.module.css";
interface Props {
  expand: boolean;
  reports: Record<string, string>[];
}
export default function Table({ expand, reports }: Props) {
  const state = ReportState();
  if (reports.length === 0) {
    return (
      <div className="d-flex justify-content-center align-items-center h-100">
        <h3>No data</h3>
      </div>
    );
  }
  return (
    <table className={`${styles.tableReport} ${expand && styles.expand}`}>
      <thead>
        <tr>
          <th>Date</th>
          <th>Energy</th>
          <th className="py-4">Utility</th>
          <th>Total utility consumption (kWh)</th>
          <th>Financial cost (£)</th>
          <th>Carbon impacts (kg)</th>
          <th> (+) Avoided and (-) Wasted Energy(kWh)</th>
          <th>Financial Cost</th>
        </tr>
      </thead>
      <tbody>
        {reports.map((r, idx) => {
          return (
            <tr key={idx}>
              <td>{ChartYears(ApiStringDateToDate(r.date))}</td>
              <td>
                {state.fuelUses
                  .filter((u) => r.usedInId === u.id)
                  .map((u) => u.use)
                  .join(", ")}
              </td>
              <td className="py-5">
                {state.fuelSources.find((f) => r.fuelSourceId === f.id)?.source}
              </td>
              <td>{r.consumption}</td>
              <td>{r.totalCost}</td>
              <td>{r.consumptionCarbonImpact}</td>
              <td>{r.waste}</td>
              <td>{r.wasteCost}</td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}
