import useAxios from "axios-hooks";
import { useCallback, useEffect } from "react";
import { createGlobalState } from "react-hooks-global-state";
import { State } from "../../State";
import FuelSource from "../../Models/FuelSource";
import { Site } from "../../Models/Site";
import { useSelector, useDispatch } from "react-redux";
import { ApiStringDateToDate } from "../../Helper/ApiStringDateToDate";

const initialState = {
  isLoading: false,
  totalYearReports: 0,
  consumptions: [] as any[],
  carbonEmissions: [] as any[],
  targetConsumption: [] as any[],
  fuelSources: [] as any[],
  fuelUses: [] as any[],
  reports: [] as any[],
  sites: [] as Site[],
  selectedFuels: undefined as FuelSource | undefined,
};
const { useGlobalState } = createGlobalState(initialState);

const ReportState = () => {
  const dispatch = useDispatch();
  const [totalYearReports, setTotalYearReports] =
    useGlobalState("totalYearReports");
  const [consumptions, setConsumptions] = useGlobalState("consumptions");
  const [targetConsumptions, setTargetConsumptions] =
    useGlobalState("targetConsumption");
  const [carbonEmissions, setCarbonEmissions] =
    useGlobalState("carbonEmissions");
  const [fuelSources, setFuelSources] = useGlobalState("fuelSources");
  const [fuelUses, setFuelUses] = useGlobalState("fuelUses");
  const [reports, setReports] = useGlobalState("reports");
  const [sites, setSites] = useGlobalState("sites");

  const site = useSelector((state: State) => state.site);
  const year = useSelector((state: State) => state.year);
  const fuelSource = useSelector((state: State) => state.fuelSource);
  const siteId = useSelector((state: State) => state.site);

  const [fuelSourceReq, getFuelSource] = useAxios<FuelSource[]>(
    {
      url: "/utility/fuelSources",
    },
    { manual: true }
  );

  const [usesReq, getUses] = useAxios<FuelSource[]>(
    {
      url: "/utility/fuelUse",
    },
    { manual: true }
  );

  const [siteReq, getSites] = useAxios<Site[]>(
    {
      url: "/business/sites",
    },
    { manual: true }
  );

  const [reportsReq, getReport] = useAxios(
    {
      url: "/dashboard/getReport",
    },
    { manual: true }
  );

  useEffect(() => {
    if (fuelSourceReq.data && fuelSourceReq.data.length !== 0) {
      setFuelSources(fuelSourceReq.data);
    }
  }, [fuelSourceReq.data]);

  useEffect(() => {
    if (usesReq.data && usesReq.data.length !== 0) {
      setFuelUses(usesReq.data);
    }
  }, [usesReq.data]);

  useEffect(() => {
    if (siteReq.data && siteReq.data?.length !== 0) {
      setSites(siteReq.data);
    }
  }, [siteReq.data, site]);

  const updateTotalYearReport = (reports: any[]) => {
    const reportsByDate = Array.from(new Map(reports.map(i => ([i.date, i]))).values())
    const total = reportsByDate.filter((i: any) => {
      return fuelSource && fuelSource.value !== -1 ? fuelSource.value === i.fuelSourceId : true;
    }).length;

    setTotalYearReports(total);
  };

  useEffect(() => {
    updateTotalYearReport(reportsReq.data?.reports || []);
  }, [fuelSource]);

  useEffect(() => {
    if (reportsReq.data) {
      setReports(reportsReq.data?.reports || []);
      updateTotalYearReport(reportsReq.data?.reports || []);
      setConsumptions(
        reportsReq.data.consumptions.map((t: any) =>
          t.map((i: any) => ({ ...i, date: ApiStringDateToDate(i.date) }))
        )
      );
      setCarbonEmissions(
        reportsReq.data.carbonEmissions.map((t: any) =>
          t.map((i: any) => ({ ...i, date: ApiStringDateToDate(i.date) }))
        )
      );
      setTargetConsumptions(
        reportsReq.data.targetConsumptions.map((t: any) =>
          t.map((i: any) => ({ ...i, date: ApiStringDateToDate(i.date) }))
        )
      );
    }
  }, [reportsReq.data]);

  const fetchReports = () => {
    const params: {
      year: number;
      fuelSourceId: undefined | number;
      siteId: undefined | number;
    } = {
      year: year.value,
      fuelSourceId:
        fuelSource && fuelSource?.value !== 0 && fuelSource.value !== -1 ? fuelSource.value : undefined,
      siteId: siteId?.id && site?.id !== -1 ? site?.id : undefined,
    };
    getReport({ params });
  };

  return {
    fetchReports,
    getSites,
    getFuelSource,
    getUses,
    sites,
    fuelSources,
    fuelUses,
    reports,
    totalYearReports,
    consumptions,
    carbonEmissions,
    targetConsumptions,
  };
};

export default ReportState;
