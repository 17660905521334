import { Dispatch } from "redux";
import { ActionTypes } from "../ActionTypes";
import { Action } from "../Actions";
import Option from "../../Models/Option";
import { Site } from "../../Models/Site";

export const ChangeDisplay = (option: Option) => {
  return (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionTypes.CHANGE_DISPLAY,
      payload: option,
    });
  };
};

export const ChangeMonth = (option: Option) => {
  return (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionTypes.CHANGE_MONTH,
      payload: option,
    });
  };
};
export const ChangeCategory = (option: Option) => {
  return (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionTypes.CHANGE_CATEGORY,
      payload: option,
    });
  };
};

export const ChangeYear = (option: Option) => {
  return (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionTypes.CHANGE_YEAR,
      payload: option,
    });
  };
};

export const ChangeFuelSource = (option: Option) => {
  return (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionTypes.CHANGE_FUELSOURCE,
      payload: option,
    });
  };
};

export const ChangeSite = (option: Site) => {
  return (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionTypes.CHANGE_SITE,
      payload: option,
    });
  };
};
