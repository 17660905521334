import { useCallback, useEffect } from "react";
import useAxios from "axios-hooks";
import {
  differenceInCalendarMonths,
  differenceInMonths,
  getDaysInMonth,
  setMonth,
} from "date-fns";
import { createGlobalState } from "react-hooks-global-state";
import { useSelector, useDispatch } from "react-redux";
import { State } from "../../State";
import Option from "../../Models/Option";
import { ChartRecord } from "../SideMenu/Base";
import { ActionTypes } from "../../State/ActionTypes";
import { Site } from "../../Models/Site";
import FuelSource from "../../Models/FuelSource";
import { ApiStringDateToDate } from "../../Helper/ApiStringDateToDate";

const initialState = {
  isLoading: false,
  selectedEnergyWasteRecord: {} as any,
  consumptions: [] as any[],
  energyTargets: [] as any[],
  sites: [] as any[],
  fuelDetails: [] as any[],
  fuelSources: [] as FuelSource[],
  carbonFootPrints: [] as any[],
  allCarbonEmissions: [] as any[],
  carbonFootPrintsPreviousYear: [] as any[],
  allCarbonEmissionsPreviousYear: [] as any[],
  energyWaste: [] as any[],
  energyWastePreviousYear: [] as any[],
  consumptionsPreviousYear: [] as any[],
  currentRecord: undefined as any | undefined,
  financialCost: [] as any,
  selectedEnergyWasteCost: {} as any,
  previousRecord: undefined as any | undefined,
  selectedMonthDaysAmount: undefined as number | undefined,
};
const { useGlobalState } = createGlobalState(initialState);

const findRecordPerMonth =
  (month: number, fuelSource?: string, isFilterFuelSource = true) =>
  (i: ChartRecord) => {
    const [, dateMonth] = i.date.split("-");
    return (
      month === Number.parseInt(dateMonth) - 1 &&
      (isFilterFuelSource
        ? fuelSource?.trim().toLowerCase() ===
          i.fuelSourceName.trim().toLowerCase()
        : true)
    );
  };

const toInt = (i: string) => parseInt(i, 10);
const mapTargetConsumption = (item: any) => {
  const dateUnits = item.date.split("-").map(toInt);
  return {
    date: new Date(dateUnits[0], dateUnits[1] - 1, 1),
    consumption: item.consumption,
    targetEnergy: item.projectedEnergy,
    fuelSourceName: item.fuelSourceName,
    siteName: item.siteName,
  };
};

const mapCarbonFootPrintToDataSet = (item: any) => {
  return item.map((i: any) => {
    const dateUnits = i.date.split("-").map(toInt);
    return {
      ...i,
      value: i.carbonEmission,
      date: new Date(dateUnits[0], dateUnits[1] - 1, 1),
    };
  });
};

// export const removeDuplicates = (arr: any) => {
//   let unique = arr[0]?.reduce((acc: any, curr: any) => {
//     if (acc.length === 0 || !acc.map((x: any) => x.date).includes(curr.date))
//       acc.push(curr);
//     return acc;
//   }, []);
//   return unique?.length > 0 ? [unique] : [];
// };

const SideMenuState = () => {
  const dispatch = useDispatch();
  const [selectedMonthDaysAmount, setSelectedMonthDaysAmount] = useGlobalState(
    "selectedMonthDaysAmount"
  );
  const [currentRecord, setCurrentRecord] = useGlobalState("currentRecord");
  const [selectedEnergyWasteRecord, setSelectedEnergyWasteRecord] =
    useGlobalState("selectedEnergyWasteRecord");
  const [selectedEnergyWasteCost, setSelectedEnergyWasteCost] = useGlobalState(
    "selectedEnergyWasteCost"
  );
  const [previousRecord, setPreviousRecord] = useGlobalState("previousRecord");
  const [fuelSources, setFuelSources] = useGlobalState("fuelSources");
  const [isLoading, setLoading] = useGlobalState("isLoading");
  const [sites, setSites] = useGlobalState("sites");
  const [consumptions, setConsumptions] = useGlobalState("consumptions");
  const [consumptionsPreviousYear, setConsumptionsPreviousYear] =
    useGlobalState("consumptionsPreviousYear");
  const [energyTargets, setEnergyTargets] = useGlobalState("energyTargets");
  const [fuelDetails, setFuelDetails] = useGlobalState("fuelDetails");
  const [energyWaste, setEnergyWaste] = useGlobalState("energyWaste");
  const [financialCost, setFinancialCost] = useGlobalState("financialCost");
  const [energyWastePreviousYear, setEnergyWastePreviousYear] = useGlobalState(
    "energyWastePreviousYear"
  );
  const [carbonFootPrints, setCarbonFootPrints] =
    useGlobalState("carbonFootPrints");
  const [allCarbonEmissions, setAllCarbonEmissions] =
    useGlobalState("allCarbonEmissions");

  const [carbonFootPrintsPreviousYear, setCarbonFootPrintsPreviousYear] =
    useGlobalState("carbonFootPrintsPreviousYear");
  const [allCarbonEmissionsPreviousYear, setAllCarbonEmissionsPreviousYear] =
    useGlobalState("allCarbonEmissionsPreviousYear");

  const year = useSelector((state: State) => state.year);
  const month = useSelector((state: State) => state.month);
  const fuelSource = useSelector((state: State) => state.fuelSource);
  const site = useSelector((state: State) => state.site);

  const [req, callStatistics] = useAxios<{
    consumptions: any[];
    energyTargets: any[];
    consumptionsDetails: any[];
  }>(
    {
      url: "/dashboard",
    },
    { manual: true }
  );

  const [sitesReq, getSites] = useAxios<Site[]>(
    {
      url: "/business/sites",
    },
    { manual: true }
  );

  const [fuelSourceReq, getFuelSource] = useAxios<FuelSource[]>(
    {
      url: "/utility/fuelSources",
    },
    { manual: true }
  );

  const [carbonFootprintReq, getCarbonFootprint] = useAxios<{
    carbonEmissions: any[];
    allCarbonEmissions: any[];
  }>(
    {
      url: "/dashboard/carbonFootprint",
    },
    { manual: true }
  );

  const [carbonFootprintPreviousYearReq, getCarbonFootprintPreviousYear] =
    useAxios<{
      carbonEmissions: any[];
      allCarbonEmissions: any[];
    }>(
      {
        url: "/dashboard/carbonFootprint",
      },
      { manual: true }
    );

  const [, getEnergyWaste] = useAxios<{
    energyTargets: any[];
  }>(
    {
      url: "/dashboard/energyWaste",
    },
    { manual: true }
  );

  const [, getEnergyWastePreviousYear] = useAxios<{
    energyWastePreviousYear: any[];
  }>(
    {
      url: "/dashboard/energyWaste",
    },
    { manual: true }
  );

  useEffect(() => {
    const sites = sitesReq.data || [];
    setSites(sites);
  }, [sitesReq.data]);

  const filterRecordsBySideParams = useCallback(
    (r: { fuelSourceName: string }[] | { fuelSourceName: string }) => {
      const arr = Array.isArray(r) ? r : [r];
      return arr.filter((row) => {
        if (fuelSource === null) {
          return true;
        } else if (fuelSource?.value === -1) {
          return true;
        } else {
          return (
            fuelSource?.label.trim().toLowerCase() ===
            row.fuelSourceName.trim().toLowerCase()
          );
        }
      });
    },
    [fuelSource]
  );

  const filterEmptyArray = useCallback((a: any[]) => {
    //NOTE: this is patch since the chart does not draw anything if the first array of the matrix is empty
    return a.length !== 0;
  }, []);

  useEffect(() => {
    setConsumptions(
      req.data?.consumptions
        .map(filterRecordsBySideParams)
        .filter(filterEmptyArray) || []
    );
    setEnergyTargets(
      req.data?.energyTargets
        .map(filterRecordsBySideParams)
        .filter(filterEmptyArray) || []
    );
    setFuelDetails(
      req.data?.consumptionsDetails.filter(filterEmptyArray) || []
    );
  }, [req.data]);

  useEffect(() => {
    setLoading(req.loading || sitesReq.loading);
  }, [req.loading, sitesReq.loading]);

  useEffect(() => {
    setLoading(fuelSourceReq.loading);
    if (fuelSourceReq.data && fuelSourceReq.data.length > 0) {
      setFuelSources(fuelSourceReq.data);
    }
  }, [fuelSourceReq.data, fuelSourceReq.loading]);

  useEffect(() => {
    setLoading(carbonFootprintReq.loading);
    if (carbonFootprintReq.data && carbonFootprintReq.data.carbonEmissions) {
      setCarbonFootPrints(
        carbonFootprintReq.data.carbonEmissions.map(filterRecordsBySideParams)
      );
      setAllCarbonEmissions(carbonFootprintReq.data.allCarbonEmissions);
    }
  }, [carbonFootprintReq.data, carbonFootprintReq.loading]);

  useEffect(() => {
    setLoading(carbonFootprintPreviousYearReq.loading);
    if (
      carbonFootprintPreviousYearReq.data &&
      carbonFootprintPreviousYearReq.data.carbonEmissions
    ) {
      setCarbonFootPrintsPreviousYear(
        carbonFootprintPreviousYearReq.data.carbonEmissions
      );
      setAllCarbonEmissionsPreviousYear(
        carbonFootprintPreviousYearReq.data.allCarbonEmissions
      );
    }
  }, [
    carbonFootprintPreviousYearReq.data,
    carbonFootprintPreviousYearReq.loading,
  ]);

  useEffect(() => {
    setConsumptions(
      req.data?.consumptions
        .map(filterRecordsBySideParams)
        .filter(filterEmptyArray) || []
    );
    setEnergyTargets(
      req.data?.energyTargets
        .map(filterRecordsBySideParams)
        .filter(filterEmptyArray) || []
    );
    setFuelDetails(
      req.data?.consumptionsDetails.filter(filterEmptyArray) || []
    );
    if (carbonFootprintReq.data && carbonFootprintReq.data.carbonEmissions) {
      setCarbonFootPrints(
        carbonFootprintReq.data.carbonEmissions.map(filterRecordsBySideParams)
      );
    }
  }, [filterRecordsBySideParams]);

  useEffect(() => {
    const collection = consumptions.flat();
    const currentMonthRecordIdx = collection.findIndex(
      findRecordPerMonth(month.value, fuelSource?.label)
    );

    if (currentMonthRecordIdx !== -1) {
      const currentRecord = collection?.[currentMonthRecordIdx];
      if (currentRecord) {
        setCurrentRecord(currentRecord);
      } else {
        setCurrentRecord(undefined);
      }

      const previousRecord = collection?.[currentMonthRecordIdx - 1];
      setPreviousRecord(previousRecord);
    } else {
      setCurrentRecord(undefined);
    }

    const selectedMonthDaysAmount = getDaysInMonth(
      new Date(year.value, month.value, 1)
    );
    setSelectedMonthDaysAmount(selectedMonthDaysAmount);
    const record =
      energyWaste && energyWaste.length > 0
        ? energyWaste[0]?.find(
            (x: any) =>
              new Date(x.date).getFullYear() === year.value &&
              new Date(x.date).getMonth() === month.value &&
              x.fuelSourceId === fuelSource?.value
          )
        : {};
    setSelectedEnergyWasteRecord(record);
    const costRecord =
      financialCost && financialCost.length > 0
        ? financialCost?.find(
            (x: any) =>
              new Date(x.date).getFullYear() === year.value &&
              new Date(x.date).getMonth() === month.value &&
              x.fuelSourceId === fuelSource?.value
          )
        : {};
    setSelectedEnergyWasteCost(costRecord);
  }, [month, consumptions, energyWaste, fuelSource]);

  const setSelectedSite = (site: Site) => {
    dispatch({
      type: ActionTypes.CHANGE_SITE,
      payload: site,
    });
  };

  const setSelectedFuelsource = (fuelSource: FuelSource) => {
    dispatch({
      type: ActionTypes.CHANGE_FUELSOURCE,
      payload: { value: fuelSource.id, label: fuelSource.source },
    });
  };

  const triggerStatisticsSearch = (p: { year?: Option; site: Site | null }) => {
    const params: Record<string, string | number> = {};
    if (p?.year?.label) {
      params.year = p.year.label;
    }
    if (p?.site?.id && p?.site?.id !== -1) {
      params.siteId = p.site.id;
    }
    return callStatistics({
      params,
    })
      .then(({ data }: any) => {
        return data;
      })
      .catch((error) => console.log("error", error));
  };

  const fetchCarbonFootPrint = (p: { year: Option; site?: Site | null }) => {
    const params: Record<string, string | number> = {
      year: p.year.label,
    };
    if (p?.site?.id && p?.site?.id !== -1) {
      params.siteId = p.site.id;
    }

    getCarbonFootprint({
      params,
    })
      .then(({ data }: any) => {
        return data;
      })
      .catch((error) => console.log("error", error));
  };

  const fetchCarbonFootPrintPreviousYear = (p: {
    year: Option;
    site?: Site | null;
  }) => {
    const params: Record<string, string | number> = {
      year: p.year.label,
    };
    if (p?.site?.id && p?.site?.id !== -1) {
      params.siteId = p.site.id;
    }

    getCarbonFootprintPreviousYear({
      params,
    })
      .then(({ data }: any) => {
        return data;
      })
      .catch((error) => console.log("error", error));
  };

  const getFootPrintForMonth = (month: number) => {
    return carbonFootPrints.flat().find((i) => {
      return ApiStringDateToDate(i.date).getMonth() === month;
    });
  };

  const getFuelDetails = () => {
    const fuels = fuelDetails || [];

    return fuels.filter(
      findRecordPerMonth(month.value, fuelSource?.label?.trim(), false)
    );
  };

  const fetchSites = () => {
    return getSites();
  };

  const getUniqueEmissions = () => {
    return Array.from(
      new Map(allCarbonEmissions.map((i) => [i.fuelSourceId, i])).values()
    );
  };

  const getUniqueEmissionsPreviousYear = () => {
    return Array.from(
      new Map(
        allCarbonEmissionsPreviousYear.map((i) => [i.fuelSourceId, i])
      ).values()
    );
  };

  const getCarbonEmissionGroupedByMonth = (paramEmissions?: any[]) => {
    const dict = new Map();

    const emissions = paramEmissions || allCarbonEmissions;

    emissions.forEach((i) => {
      const found = dict.get(i.date);
      found ? dict.set(i.date, found.concat([i])) : dict.set(i.date, [i]);
    });

    return Array.from(dict.values())
      .map((i) => {
        const item: any = {};
        i.forEach((a: any) => {
          item[a.fuelSourceName] = a.carbonEmission;
          item.date = ApiStringDateToDate(a.date);
        });
        return item;
      })
      .flat();
  };

  const getCarbonPrintStartingFromSelectedMonth = (p?: {
    filterBySelectedFuelSource: boolean;
  }) => {
    const filteredEmissions = allCarbonEmissions.flat().filter((i) => {
      const correctFuelSource =
        p?.filterBySelectedFuelSource === true
          ? fuelSource?.value === i.fuelSourceId
          : true;
      const diff = differenceInCalendarMonths(
        ApiStringDateToDate(i.date),
        new Date(year.value, month.value, 1)
      );
      return diff <= 3 && diff >= 0 && correctFuelSource;
    });

    return filteredEmissions;
  };

  const fetchEnergyWaste = (p: {
    year: Option;
    fuelSource?: Option | null;
    site?: Site | null;
  }) => {
    const params: Record<string, string | number> = {
      year: p.year.label,
    };
    if (p.fuelSource?.value) {
      params.fuelSource = p.fuelSource.value;
    }
    if (p?.site?.id && p?.site?.id !== -1) {
      params.siteId = p.site.id;
    }
    return getEnergyWaste({
      params,
    })
      .then(({ data }: any) => {
        setEnergyWaste(data?.waste);
        setConsumptions(
          data?.consumptions
            .map((c: any) => ({ ...c, cost: c.totalCost }))
            .filter(
              (c: any) => Number.parseInt(c.date.slice(0, 4)) === year.value
            )
            .map(filterRecordsBySideParams)
            .filter(filterEmptyArray)
        );
        setFinancialCost(data?.financialCost);
        const record = data.waste.find(
          (x: any) =>
            new Date(x.date).getFullYear() === year.value &&
            new Date(x.date).getMonth() === month.value &&
            x.fuelSourceId === p.fuelSource?.value
        );
        setSelectedEnergyWasteRecord(record);
        const costRecord = data?.financialCost?.find(
          (x: any) =>
            new Date(x.date).getFullYear() === year.value &&
            new Date(x.date).getMonth() === month.value &&
            x.fuelSourceId === p.fuelSource?.value
        );
        setSelectedEnergyWasteCost(costRecord);
        return data;
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const fetchEnergyWastePreviousYear = (p: {
    year: Option;
    fuelSource?: Option | null;
    site?: Site | null;
  }) => {
    const params: Record<string, string | number> = {
      year: Number(p.year.label) - 1,
    };
    if (p.fuelSource?.value) {
      params.fuelSource = p.fuelSource.value;
    }
    if (p?.site?.id && p?.site?.id !== -1) {
      params.siteId = p.site.id;
    }
    return getEnergyWastePreviousYear({
      params,
    })
      .then(({ data }: any) => {
        setEnergyWastePreviousYear(data?.waste);
        setConsumptionsPreviousYear(data?.consumptions);
        return data;
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const getEnergyWasteBySelectedFuel = (p?: {
    fromLastMonthAmount?: number;
  }) => {
    let arr = energyWaste
      .map((i) => ({ ...i, date: ApiStringDateToDate(i.date) }))
      .filter((i) => i.fuelSourceId === fuelSource?.value);

    if (p?.fromLastMonthAmount !== undefined) {
      const selectedMonth = setMonth(new Date(), month.value);
      arr.filter((i) => differenceInMonths(i.date, selectedMonth) > 3);
    }
    return arr;
  };

  const getCurrentEneryTarget = useCallback(() => {
    const found = energyTargets.flat().find((target) => {
      const matchedFuel = fuelSource
        ? target.fuelSourceId === fuelSource.value
        : true;
      const matchedMonth =
        Number.parseInt(target.date.slice(5, 7)) - 1 === month.value;
      return matchedFuel && matchedMonth;
    });
    return found;
  }, [energyTargets, fuelSource, month]);

  const getCurrentWaste = useCallback(() => {
    const found = energyWaste.flat().find((target) => {
      const matchedFuel = fuelSource
        ? target.fuelSourceId === fuelSource.value
        : true;
      const matchedMonth =
        Number.parseInt(target.date.slice(5, 7)) - 1 === month.value;
      return matchedFuel && matchedMonth;
    });
    return found;
  }, [energyWaste, fuelSource, month]);

  return {
    isLoading: isLoading,
    consumptions,
    energyTargets: energyTargets.map((i) => i.map(mapTargetConsumption)),
    sites,
    currentRecord,
    previousRecord,
    selectedYear: year,
    selectedMonthDaysAmount,
    fuelSources,
    carbonFootPrint: carbonFootPrints.map(mapCarbonFootPrintToDataSet) || [],
    carbonFootPrintPreviousYear:
      carbonFootPrintsPreviousYear.map(mapCarbonFootPrintToDataSet) || [],
    allCarbonEmissions,
    allCarbonEmissionsPreviousYear,
    getEnergyWasteBySelectedFuel,
    setSelectedFuelsource,
    fetchEnergyWaste,
    fetchEnergyWastePreviousYear,
    getCarbonPrintStartingFromSelectedMonth,
    getCarbonEmissionGroupedByMonth,
    getUniqueEmissions,
    getUniqueEmissionsPreviousYear,
    fetchCarbonFootPrint,
    fetchCarbonFootPrintPreviousYear,
    fetchSites,
    triggerStatisticsSearch,
    getFuelDetails,
    getFuelSource,
    getCarbonFootprint,
    getFootPrintForMonth,
    selectedEnergyWasteRecord,
    selectedEnergyWasteCost,
    energyWaste: energyWaste?.map((item: any) =>
      item?.map((i: any) => ({ ...i, date: ApiStringDateToDate(i.date) }))
    ),
    financialCost: financialCost,
    energyWastePreviousYear: energyWastePreviousYear?.map((item: any) =>
      item?.map((i: any) => ({ ...i, date: ApiStringDateToDate(i.date) }))
    ),
    getCurrentEneryTarget,
    getCurrentWaste,
  };
};
export default SideMenuState;
