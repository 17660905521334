import { ActionTypes } from "../ActionTypes";
import { Action } from "../Actions";
import Option from "../../Models/Option";

const initialState: Option = {
  value: 0,
  label: "All",
};

const reducer = (state: Option = initialState, action: Action) => {
  switch (action.type) {
    case ActionTypes.CHANGE_CATEGORY:
      return action.payload;
    default:
      return state;
  }
};
export default reducer;
