import { combineReducers } from "redux";
import DisplayReducer from "./DisplayReducer";
import MonthReducer from "./MonthReducer";
import YearReducer from "./YearReducer";
import FuelReducer from "./FuelReducer";
import SiteReducer from "./SiteReducer";
import CategoryReducer from "./CategoryReducers";

const reducers = combineReducers({
  display: DisplayReducer,
  year: YearReducer,
  month: MonthReducer,
  fuelSource: FuelReducer,
  site: SiteReducer,
  category: CategoryReducer,
});

export default reducers;

export type State = ReturnType<typeof reducers>;
