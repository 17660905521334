import { BrowserRouter, Route, Redirect } from "react-router-dom";
import About from "./About";
import "./App.css";
import Blog from "./Blog";
import Business from "./Settings/Business";
import Contact from "./Contact";
import Gallery from "./Galllery";
import Home from "./Home";
import Layout from "./Layout";
import Progress from "./Progress";
import Recognitions from "./Recognitions";
import Settings from "./Settings/Settings";
import Team from "./Team";
import Work from "./Work";
import FormsTop from "./FormsTop";
import BuildingEnergyPatterns from "./Settings/BuildingEnergyPatterns/BuildingEnergyPatterns";
import UtilityAndEmissions from "./Settings/UtilityAndEmissions";
import BuildingEnergyLog from "./Settings/BuildingEnergyLog";
import ReviewAndProgrammes from "./Settings/ReviewAndProgrammes";
import Sites from "./Settings/Sites";
import Analytics from "./Analytics/Analytics";
import UserManagement from "./UserManagement";
import UtilityLeague from "./Analytics/UtilityLeague/UtilityLeague";
import CarbonFootprints from "./Analytics/CarbonFootprint/CarbonFootprints";
import EnergyWaste from "./Analytics/EnergyWaste/EnergyWaste";
import EnergySavingTips from "./Analytics/EnergySavingTips";
import Reports from "./Analytics/Report/Reports";
import EnergyLog from "./Analytics/EnergyLog/EnergyLog";
import Portfolio from "./Analytics/Portfolio/Portfolio";
import Login from "./Login";
import PrivateRoute from "./Components/PrivateRoute";
import UserManagementForm from "./Settings/UserManagementForm";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Route path="/" exact>
          <Layout>
            <Home />
          </Layout>
        </Route>
        <Route path="/home" exact>
          <Layout>
            <Home />
          </Layout>
        </Route>
        <Route path="/About">
          <Layout>
            <About />
          </Layout>
        </Route>
        <Route path="/Recognitions">
          <Layout>
            <Recognitions />
          </Layout>
        </Route>
        <Route path="/Work" component={Work} />
        <Route path="/Progress" component={Progress} />
        <Route path="/Team" component={Team} />
        <Route path="/Blog" component={Blog} />
        <Route path="/Gallery" component={Gallery} />
        <Route path="/Contact" component={Contact} />
        <Route path="/home/Settings" exact>
          <Layout>
            <FormsTop />
            <Settings />
          </Layout>
        </Route>
        <Route path="/home/settings/business">
          <Layout>
            <FormsTop />
            <Business />
          </Layout>
        </Route>
        <PrivateRoute path="/home/settings/building-energy-patterns">
          <Layout>
            <FormsTop />
            <BuildingEnergyPatterns />
          </Layout>
        </PrivateRoute>
        <PrivateRoute path="/home/settings/utilityandemissions">
          <Layout>
            <FormsTop />
            <UtilityAndEmissions />
          </Layout>
        </PrivateRoute>
        <PrivateRoute path="/home/settings/usermanagement">
          <Layout>
            <FormsTop />
            <UserManagementForm />
          </Layout>
        </PrivateRoute>
        <PrivateRoute path="/home/settings/buildingenergylog">
          <Layout>
            <FormsTop />
            <BuildingEnergyLog />
          </Layout>
        </PrivateRoute>
        <PrivateRoute path="/home/settings/reviewandprogrammes">
          <Layout>
            <FormsTop />
            <ReviewAndProgrammes />
          </Layout>
        </PrivateRoute>
        <PrivateRoute path="/home/settings/sites">
          <Layout>
            <FormsTop />
            <Sites />
          </Layout>
        </PrivateRoute>
        <PrivateRoute path="/home/analytics">
          <Layout>
            <FormsTop />
            <Analytics />
          </Layout>
        </PrivateRoute>
        <PrivateRoute path="/home/user-management">
          <Layout>
            <FormsTop />
            <UserManagement />
          </Layout>
        </PrivateRoute>
        <PrivateRoute
          path="/analytics/utilityleague"
          component={UtilityLeague}
        />
        <PrivateRoute
          path="/analytics/carbonfootprints"
          component={CarbonFootprints}
        />
        <Route path="/analytics/energywaste" component={EnergyWaste} />
        <Route
          path="/analytics/energysavingtips"
          component={EnergySavingTips}
        />
        <Route path="/analytics/reports" component={Reports} />
        <Route path="/analytics/energylog" component={EnergyLog} />
        <Route path="/analytics/Portfolio" component={Portfolio} />
        <Route path="/login" component={Login} />
        {/* comment out for refresh page issue */}
        {/* <Route path="*" render={() => <Redirect to="/" />} /> */}
      </BrowserRouter>
    </div>
  );
}

export default App;
