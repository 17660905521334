import { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { useAlert } from "react-alert";
import FileImportModal from "./Comsumption/ConsumptionImportModal";
import SimpleImportModal from "./Comsumption/SimpleImportModal";
import { getUserData } from "../Services/Auth";

type ModalName =
  | "CONSUMPTION"
  | "LOG"
  | "BUSINESS"
  | "BUILDING_ENERGY"
  | "UTILITY_AND_EMISSIONS"
  | "PATTERNS";

const UseModalState = (p?: {
  onUploadDone?: (
    name: ModalName,
    ctx: { closeModal: (m: ModalName) => () => void }
  ) => void;
}) => {
  const [store, setStore] = useState<Record<ModalName, boolean>>({
    CONSUMPTION: false,
    LOG: false,
    BUSINESS: false,
    BUILDING_ENERGY: false,
    PATTERNS: false,
    UTILITY_AND_EMISSIONS: false,
  });

  const showModal = (name: ModalName) => () => {
    setStore((s) => {
      return {
        ...s,
        [name]: true,
      };
    });
  };

  const closeModal = (name: ModalName) => () => {
    setStore((s) => {
      return {
        ...s,
        [name]: false,
      };
    });
  };

  const onUploadDone = (name: ModalName) => () => {
    if (p?.onUploadDone) {
      p.onUploadDone(name, { closeModal });
    }
  };

  const isOpen = (name: ModalName) => {
    return store[name] === true;
  };

  return {
    showModal,
    closeModal,
    onUploadDone,
    isOpen,
  };
};

export default function Settings() {
  const alert = useAlert();
  const history = useHistory();
  const user = getUserData();
  const modalState = UseModalState({
    onUploadDone: (name, { closeModal }) => {
      if (name === "BUSINESS" || name === "PATTERNS") {
        alert.success("Uploaded");
        closeModal(name)();
      } else {
        history.push("/analytics/utilityleague");
      }
    },
  });

  return (
    <>
      <section className="mainLinks w-55 mt-5">
        <div className="row gx-5">
          <div className="col-lg-4 mb-4 col-12">
            <div className="card rounded-custom h-100">
              <div className="card-header bg-white rounded-custom-top text-center fw-bold py-4">
                BUSINESS
              </div>
              <Link
                to="/home/settings/business"
                className="btn shadow-none rounded-top-0 card-body text-center rounded-custom-bottom py-4 fw-bold"
              >
                VIEW
              </Link>
            </div>
          </div>
          <div className="col-lg-4 mb-4 col-12">
            <div className="card rounded-custom h-100">
              <div className="card-header rounded-custom-top bg-white text-center fw-bold py-4">
                Sites
              </div>
              <Link
                to="/home/settings/sites"
                className="btn shadow-none rounded-top-0 card-body text-center rounded-custom-bottom py-4 fw-bold"
              >
                VIEW
              </Link>
            </div>
          </div>
          <div className="col-lg-4 mb-4 col-12">
            <div className="card rounded-custom h-100">
              <div className="card-header rounded-custom-top bg-white text-center fw-bold py-4">
                Building Energy Profile
              </div>
              <Link
                to="/home/settings/building-energy-patterns"
                className="btn shadow-none rounded-top-0 card-body text-center rounded-custom-bottom py-4 fw-bold"
              >
                VIEW
              </Link>
            </div>
          </div>
          <div className="col-lg-4 mb-4 col-12">
            <div className="card rounded-custom h-100">
              <div className="card-header bg-white rounded-custom-top text-center fw-bold py-4">
                Utility and Emissions
              </div>
              <Link
                to="/home/settings/utilityandemissions"
                className="btn shadow-none rounded-top-0 card-body text-center rounded-custom-bottom py-4 fw-bold"
              >
                VIEW
              </Link>
            </div>
          </div>
          <div className="col-lg-4 mb-4 col-12">
            <div className="card rounded-custom h-100">
              <div className="card-header rounded-custom-top bg-white text-center fw-bold py-4">
                User Management
              </div>
              <Link
                to="/home/settings/usermanagement"
                className="btn shadow-none rounded-top-0 card-body text-center rounded-custom-bottom py-4 fw-bold"
              >
                VIEW
              </Link>
            </div>
          </div>
          <div className="col-lg-4 mb-4 col-12 setting-menu-item-deactivated">
            <div className="card rounded-custom h-100">
              <div className="card-header rounded-custom-top bg-white text-center fw-bold py-4">
                Building energy log
              </div>
              <Link
                to="/home/settings/buildingenergylog"
                className="btn shadow-none rounded-top-0 card-body text-center rounded-custom-bottom py-4 fw-bold"
              >
                VIEW
              </Link>
            </div>
          </div>
          <div className="setting-menu-item-deactivated col-lg-4 mb-4 col-12">
            <div className="card rounded-custom h-100">
              <div className="card-header rounded-custom-top bg-white text-center fw-bold py-4">
                Centralised/Decentralised Load
              </div>
              <Link
                to="#"
                className="btn shadow-none rounded-top-0 card-body text-center rounded-custom-bottom py-4 fw-bold"
              >
                VIEW
              </Link>
            </div>
          </div>
          <div className="setting-menu-item-deactivated col-lg-4 mb-4 col-12">
            <div className="card rounded-custom h-100">
              <div className="card-header bg-white rounded-custom-top text-center fw-bold py-4">
                Review
              </div>
              <Link
                to="/home/settings/reviewandprogrammes"
                className="btn shadow-none rounded-top-0 card-body text-center rounded-custom-bottom py-4 fw-bold"
              >
                VIEW
              </Link>
            </div>
          </div>
        </div>
      </section>
      <section className="w-55 mb-5">
        <div
          className="card rounded-custom"
          style={{
            filter: "drop-shadow(0px 0px 20px #E7E7E866)",
          }}
        >
          <div className="card-header bg-white py-3 fw-bold text-center rounded-custom-top">
            <h2 className="fw-bold">Files</h2>
          </div>
          <div className="card-body p-0 overflow-auto">
            <table className="table table-striped table-settings-bordered mb-0">
              <thead className="text-white">
                <tr
                  style={{
                    fontSize: "12px",
                  }}
                >
                  <th className="border-end"></th>
                  <th className="text-center border-end">Last update</th>
                  <th className="text-center border-end">Notes</th>
                  <th className="text-center border-end">Files</th>
                  <th className="text-center border-end">Action</th>
                  <th className="text-center border-end">History</th>
                  <th className="text-center">Upload file</th>
                </tr>
              </thead>
              <tbody>
                <tr></tr>
                <tr>
                  <th className="fw-bold align-middle">Site(s)</th>
                  <td className="text-center align-middle">
                    {new Date().toLocaleDateString()}
                  </td>
                  <td className="text-center align-middle">Note A</td>
                  <td className="text-center align-middle">File A</td>
                  <td className="text-muted text-center align-middle fs-12">
                    UPDATED BY {user?.businessName}
                  </td>
                  <td className="text-center align-middle bg-light-red fw-semibold">
                    View
                  </td>
                  <td className="text-center align-middle">
                    <button
                      className="btn btn-outline-danger"
                      type="button"
                      onClick={modalState.showModal("BUSINESS")}
                    >
                      UPLOAD
                    </button>
                  </td>
                </tr>
                <tr>
                  <th className="fw-bold align-middle">
                    Energy and Emissions Profile
                  </th>
                  <td className="text-center align-middle">
                    {new Date().toLocaleDateString()}
                  </td>
                  <td className="text-center align-middle">Note A</td>
                  <td className="text-center align-middle">File A</td>
                  <td className="text-muted text-center align-middle fs-12">
                    UPDATED BY {user?.businessName}
                  </td>
                  <td className="text-center align-middle bg-light-red fw-semibold">
                    View
                  </td>
                  <td className="text-center align-middle ">
                    <button
                      className="btn btn-outline-danger"
                      type="button"
                      onClick={modalState.showModal("PATTERNS")}
                    >
                      UPLOAD
                    </button>
                  </td>
                </tr>
                <tr>
                  <th className="fw-bold align-middle">
                    Utility And Emissions
                  </th>
                  <td className="text-center align-middle">
                    {new Date().toLocaleDateString()}
                  </td>
                  <td className="text-center align-middle">Note A</td>
                  <td className="text-center align-middle">File A</td>
                  <td className="text-muted text-center align-middle fs-12">
                    UPDATED BY {user?.businessName}
                  </td>
                  <td className="text-center align-middle bg-light-red fw-semibold">
                    View
                  </td>
                  <td className="text-center align-middle ">
                    <button
                      className="btn btn-outline-danger"
                      type="button"
                      onClick={modalState.showModal("UTILITY_AND_EMISSIONS")}
                    >
                      UPLOAD
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </section>
      <FileImportModal
        url="/utility/importUtilityEmissions"
        show={modalState.isOpen("CONSUMPTION")}
        onCancel={modalState.closeModal("CONSUMPTION")}
        onUploadDone={modalState.onUploadDone("CONSUMPTION")}
      />
      <FileImportModal
        url="/utility/importLogs"
        show={modalState.isOpen("LOG")}
        onCancel={modalState.closeModal("LOG")}
        onUploadDone={modalState.onUploadDone("LOG")}
      />
      <SimpleImportModal
        title="Business"
        url="/business/importBusiness"
        show={modalState.isOpen("BUSINESS")}
        onCancel={modalState.closeModal("BUSINESS")}
        onUploadDone={modalState.onUploadDone("BUSINESS")}
      />
      <SimpleImportModal
        title="Building Energy"
        url="/business/importTenants"
        show={modalState.isOpen("BUILDING_ENERGY")}
        onCancel={modalState.closeModal("BUILDING_ENERGY")}
        onUploadDone={modalState.onUploadDone("BUILDING_ENERGY")}
      />
      <SimpleImportModal
        title="Building and Devices"
        url="/business/importPatterns"
        show={modalState.isOpen("PATTERNS")}
        onCancel={modalState.closeModal("PATTERNS")}
        onUploadDone={modalState.onUploadDone("PATTERNS")}
      />
      <SimpleImportModal
        title="Utility And Emissions"
        url="/utility/importUtilityEmissions"
        show={modalState.isOpen("UTILITY_AND_EMISSIONS")}
        onCancel={modalState.closeModal("UTILITY_AND_EMISSIONS")}
        onUploadDone={modalState.onUploadDone("UTILITY_AND_EMISSIONS")}
      />
    </>
  );
}
