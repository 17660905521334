interface props {
  text1: string;
  value1: string;
  color1: string;
  text2: string;
  value2: string;
  color2: string;
}
export default function OverlappingInfo({
  text1,
  value1,
  text2,
  value2,
  color1,
  color2,
}: props) {
  return (
    <>
      <div
        className="fw-semibold  d-inline-flex flex-column justify-content-center py-1"
        style={{
          backgroundColor: color1,
          paddingLeft: "20px",
          borderRadius: "18px",
          paddingRight: "40px",
          color: "white",
          textAlign: "center",
          minHeight: "30px",
        }}
      >
        <span
          style={{
            fontSize: "10px",
            lineHeight: "10px",
          }}
        >
          {text1}
        </span>
        <span
          style={{
            fontSize: "12px",
            lineHeight: "14px",
          }}
        >
          {value1}
        </span>
      </div>
      <div
        className="fw-semibold d-inline-flex flex-column justify-content-center py-1"
        style={{
          backgroundColor: color2,
          paddingLeft: "20px",
          borderRadius: "18px",
          paddingRight: "20px",
          marginLeft: "-30px",
          color: "white",
          textAlign: "center",
          minHeight: "30px",
        }}
      >
        <span
          style={{
            fontSize: "10px",
            lineHeight: "10px",
          }}
        >
          {text2}
        </span>
        <span
          style={{
            fontSize: "12px",
            lineHeight: "14px",
          }}
        >
          {value2}
        </span>
      </div>
    </>
  );
}
