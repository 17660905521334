import {
  ResponsiveContainer,
  CartesianGrid,
  XAxis,
  YAxis,
  BarChart as Bars,
  Bar,
} from "recharts";
import style from "../analytics.module.css";
import ChartDates, { ChartYears } from "../../Helper/DateFormat";

// const data = [
//   {
//     date: new Date(2020, 0, 1),
//     value: 400,
//   },
//   {
//     date: new Date(2020, 1, 1),
//     value: 325,
//   },
//   {
//     date: new Date(2020, 2, 1),
//     value: 150,
//   },
//   {
//     date: new Date(2020, 3, 1),
//     value: 180,
//   },
// ];

interface props {
  expand?: boolean;
  selectedIndex: number;
  records: any[];
  // records: { date: Date; value: number }[];
  loading: boolean;
}

const CustomBar = ({ fill, x, y, width, height, expand, payload }: any) => {
  console.log("payload => ", payload);

  return (
    <g>
      <defs>
        <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
          <stop offset="0%" stopColor="#FFFFFF" stopOpacity={0.1} />
          <stop offset="65%" stopColor="#4D505C" stopOpacity={0.42} />
        </linearGradient>
      </defs>
      <g>
        <rect
          x={x}
          y={y}
          width={width}
          height={height}
          fill="#E97871"
          rx={20}
          ry={20}
        />
        <text
          x={x + width / 2}
          y={y + height / 2}
          dominant-baseline="middle"
          text-anchor="middle"
          fill="white"
        >
          {payload.differentInPercentage !== undefined
            ? payload.selected
              ? "100%"
              : `${payload.differentInPercentage > 0 ? "↑ " : "↓ "}${Math.abs(
                  payload.differentInPercentage
                )}%`
            : ""}
        </text>
      </g>
      {payload.selected && (
        <>
          <rect
            x={x}
            y={0}
            width={width}
            height="100%"
            stroke="none"
            fill="url(#colorUv)"
          />
          <rect
            x={x + width}
            y={expand ? 15 : 0}
            width={1}
            height="100%"
            stroke="none"
            fill="#4D505C"
            style={{
              height: expand ? "calc(100% - 50px)" : "calc(100% - 35px)",
              transform: "translate(2.5%,0px)",
            }}
          />
        </>
      )}
    </g>
  );
};

const Tick = ({
  x,
  y,
  payload,
  itemType,
  expand,
  selectedIndex,
  data,
}: any) => {
  return (
    <g transform={`translate(${x},${y})`}>
      <text
        x={0}
        y={0}
        dy={20}
        textAnchor="middle"
        fill={payload.value === selectedIndex ? "#4D505C" : "#8E8E8E"}
        className="axis"
        fontWeight={payload.value === selectedIndex ? "bold" : "normal"}
      >
        {ChartYears(data[payload.value].date)}
      </text>
    </g>
  );
};

const TickY = ({
  x,
  y,
  payload,
  itemType,
  expand,
  selectedIndex,
  data,
}: any) => {
  return (
    <g transform={`translate(${x},${y})`}>
      <text x={0} y={0} textAnchor="end" fill="#000000" className="axis">
        {payload.value} kWh
      </text>
    </g>
  );
};

export default function LargeBarChart({
  expand,
  selectedIndex,
  records,
  loading,
}: props) {
  const filterRecords = records.map((x) => ({
    ...x,
    value: Math.abs(x.value).toFixed(0),
  }));

  const transformedData = filterRecords.map((x, i) => {
    return {
      index: i,
      ...x,
      selected: i === 0 ? 1000 : null,
    };
  });

  if (loading) {
    return (
      <div className="h-100 w-100 d-flex justify-content-between">
        <div
          style={{
            width: "calc(100%)",
          }}
        >
          <div className="d-flex justify-content-center align-items-center h-100">
            <div className="spinner-border" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (records.length === 0) {
    return (
      <div className="h-100 w-100 d-flex justify-content-between">
        <div
          style={{
            width: "calc(100%)",
          }}
        >
          <div className="d-flex justify-content-center align-items-center h-100">
            <h4>No data</h4>
          </div>
        </div>
      </div>
    );
  }

  const getYAxisValue = () => {
    const maxValue = Math.max(...filterRecords.map((x) => x.value));
    return [0, maxValue + 10 || 1000];
  };

  return (
    <ResponsiveContainer width="99%" height="100%">
      <Bars
        data={transformedData}
        margin={{
          right: 15,
          left: expand ? 30 : 0,
          top: expand ? 15 : 0,
        }}
        id="wasteSavingGraph"
      >
        <CartesianGrid opacity={0.1} />
        <XAxis
          dataKey="index"
          strokeWidth={0}
          stroke="#8E8E8E"
          type="category"
          allowDecimals={false}
          domain={["dataMin", "dataMax"]}
          tickCount={22}
          tick={
            <Tick
              expand={expand}
              selectedIndex={selectedIndex}
              data={filterRecords}
            />
          }
        />
        <YAxis
          tickLine={false}
          strokeWidth={0}
          stroke="#000"
          domain={getYAxisValue()}
          textAnchor="end"
          tick={
            <TickY
              expand={expand}
              selectedIndex={selectedIndex}
              data={filterRecords}
            />
          }
        />

        <Bar
          type="monotone"
          dataKey="value"
          fill="#4989C6"
          legendType="circle"
          shape={<CustomBar expand={expand} />}
          //@ts-ignore
          background={{ fill: "#E7E7E8", radius: [20, 20, 20, 20] }}
        />
      </Bars>
    </ResponsiveContainer>
  );
}
