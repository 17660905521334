import React, { useEffect } from "react";
import * as d3 from "d3";

const D3DisplayDiff = (props) => {
  useEffect(() => {
    const svg = d3
      .select("#d3-container")
      .attr("width", props.width)
      .attr("height", 100);

    // // Clear any existing elements
    // svg.selectAll("*").remove();

    if (props.difference === 0) {
    } else if (props.difference > 0) {
      svg
        .append("text")
        .attr("x", props.x + 40)
        .attr("y", 10)
        .attr("class", "arrow")
        .text("↓")
        .style("font-size", "10px")
        .style("fill", "green");

      // The percentage difference
      svg
        .append("text")
        .attr("x", props.x + 50)
        .attr("y", 10)
        .attr("class", "percentage")
        .text(Math.abs(props.difference) + "%")
        .style("font-size", "10px")
        .style("fill", "green");
    } else {
      // The downward arrow
      svg
        .append("text")
        .attr("x", props.x + 40)
        .attr("y", 10)
        .attr("class", "arrow")
        .text("↑")
        .style("font-size", "10px")
        .style("fill", "red");

      // The percentage difference
      svg
        .append("text")
        .attr("x", props.x + 50)
        .attr("y", 10)
        .attr("class", "percentage")
        .text(Math.abs(props.difference) + "%")
        .style("font-size", "10px")
        .style("fill", "red");
    }
  }, []);

  if (props.difference == 0) return <></>;
  return <svg id="d3-container"></svg>;
};

export default D3DisplayDiff;
