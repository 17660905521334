import { useEffect, useState } from "react";
import style from "../analytics.module.css";
import { Site } from "../../Models/Site";
import { useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import { ActionCreators } from "../../State";
import useQueryManager from "../../Hooks/useQueryManager";

interface Props {
  sites: Site[];
  currentSite?: Site | null;
  hideAllOption?: boolean;
}
export default function SiteList({
  sites,
  currentSite,
  hideAllOption = false,
}: Props) {
  const urlQuery = useQueryManager();
  const [showSite, setShowSite] = useState(true);
  const dispatch = useDispatch();
  const { ChangeSite } = bindActionCreators(ActionCreators, dispatch);
  const setSelectedSite = (s: Site) => () => {
    ChangeSite(s);
    urlQuery.set("site", s.id.toString());
  };

  useEffect(() => {
    const site = urlQuery.get("site");
    const s = sites.find((s) => s.id.toString() === site);
    if (s) {
      ChangeSite(s);
    }
  }, [urlQuery.get("site"), sites]);

  return (
    <div className="d-flex flex-column border-top border-bottom py-3 px-4">
      <label
        className={`form-label ${style.selectLabel} d-flex justify-content-between align-items-center`}
      >
        Sites
        {showSite ? (
          <button
            className="btn btn-outline-success rounded-circle"
            onClick={() => {
              setShowSite(false);
            }}
          >
            -
          </button>
        ) : (
          <button
            className="btn btn-outline-success rounded-circle"
            onClick={() => {
              setShowSite(true);
            }}
          >
            +
          </button>
        )}
      </label>
      {showSite &&
        sites.map((s) => {
          return (
            <label key={s.name + s.code} className="checkbox-container mb-2">
              <input
                type="radio"
                checked={currentSite?.id === s.id || currentSite?.id === -1}
                onClick={setSelectedSite(s)}
              />
              <span className="checkmark"></span>
              <span className="label">{s.name}</span>
            </label>
          );
        })}
      {showSite && hideAllOption !== true && (
        <label className="checkbox-container mb-2">
          <input
            type="radio"
            checked={currentSite?.id === -1}
            onClick={setSelectedSite({
              id: -1,
              code: "",
              vat: 0,
              type: "",
              name: "",
              address: "",
              postCode: "",
              town: "",
              population: -1,
              size: -1,
              workinghours: "",
              currencyCode: "",
              countryId: 0,
              stateId: 0,
            })}
          />
          <span className="checkmark"></span>
          <span className="label">All</span>
        </label>
      )}
    </div>
  );
}
