import { NavLink, useLocation } from "react-router-dom";
import style from "./analytics.module.css";
import { useMemo } from "react";

function useQuery() {
  const { search } = useLocation();

  return useMemo(() => new URLSearchParams(search), [search]);
}

export default function SideBar() {
  const { search } = useLocation();
  let query = useQuery();
  query.delete("site");

  const energyWasteParams = query.toString();
  return (
    <div className={style.leftSideBar}>
      <div className={style.logo}>
        <img
          src="/icons/gpad_logo_2.png"
          alt="icons"
          className="img-fluid ps-5"
        />
      </div>

      <nav className="d-flex text-white flex-column flex-fill">
        <NavLink
          to={`/analytics/utilityleague${search}`}
          className="flex-fill d-flex align-items-center ps-5"
          activeClassName={style.active}
        >
          Energy League
        </NavLink>
        <NavLink
          to={`/analytics/carbonfootprints${search}`}
          className="flex-fill d-flex align-items-center ps-5"
          activeClassName={style.active}
        >
          Carbon Footprints
        </NavLink>
        <NavLink
          to={`/analytics/energywaste?${energyWasteParams}`}
          className="flex-fill d-flex align-items-center ps-5"
          activeClassName={style.active}
        >
          Avoided / Wasted Energy
        </NavLink>
        <NavLink
          to={`/analytics/energySavingTips${search}`}
          className="flex-fill d-flex align-items-center ps-5"
          activeClassName={style.active}
        >
          Energy Saving Tips
        </NavLink>
        <NavLink
          to={`/analytics/reports${search}`}
          className="flex-fill d-flex align-items-center ps-5"
          activeClassName={style.active}
        >
          Reports
        </NavLink>
        <NavLink
          to={`/analytics/portfolio${search}`}
          activeClassName={style.active}
          className="flex-fill d-flex align-items-center ps-5"
        >
          Portfolio
        </NavLink>
      </nav>
    </div>
  );
}
